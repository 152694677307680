import React, {forwardRef} from "react";
import {classNames} from "../../tools/react.dom.helpers";

const Modal = forwardRef( ({id, className, children, type = Modal.TYPE.DEFAULT, size = Modal.SIZE.S, cancelModal}, ref) =>
    <div
        ref={ref}
        id={id}
        onClick={(e) => {cancelModal && !$(e.target).closest('.modal-dialog').length ? cancelModal() : null;}}
        className={classNames('modal', 'fade', className && 'modal__' + className, type && 'modal-' + type, size && size )}
        tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                {children}
            </div>
        </div>
    </div>
);

export default Modal;


Modal.TYPE = {
    DEFAULT: 'default',
    INFO: 'info',
    WARNING: 'warning',
}

Modal.SIZE = {
    XXL: "modal-xxl",  //800
    XL: "modal-xl",  //800
    L: 'modal-l',   // 620
    S: 'modal-s'    // 480
}
