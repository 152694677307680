import React from "react";
import {Heading} from "../index";

const ModalBodyMobHeader = ({title, children}) => (
    <div className="modal-title modal-title__show-on-mob">
        {title && <Heading type={Heading.TYPE.H3} title={title}/>}
        {children}
    </div>
);

export default ModalBodyMobHeader;