import React from "react";
import {ClosePopupButton} from "../buttons/button";
import {Button, Icon} from "../index";

const ModalMobileCloseBtn = ({onClick, children, ...otherProps}) => (
    <div className="modal-header modal-header__close">
        <ClosePopupButton template={Button.TEMPLATE.BACK} icon={Icon.ICON.BACK} onClick={onClick} caption={'Back'} {...otherProps}/>
        {children}
    </div>
)

export default ModalMobileCloseBtn;
