import React from "react";
import {Heading} from "../index";
import {classNames} from "../../tools/react.dom.helpers";
import ModalMobileCloseBtn from "./ModalMobileCloseBtn";

const ModalHeader = ({className, title, children}) => (
    <>
        <ModalMobileCloseBtn/>
        <div className={classNames('modal-header', className && className)}>
            {title && <Heading className={'modal-title'} type={Heading.TYPE.H3} title={title}/>}
            {children}
        </div>
    </>

);

export default ModalHeader;