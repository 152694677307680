import React from "react";
import {classNames} from "../tools/react.dom.helpers";


const Grid = ({
       className, children,
       direction= Grid.DIRECTION.ROW,
       gapY = Grid.GAP.GAP_8,
       gapX = Grid.GAP.GAP_8,
       align = Grid.ALIGN.BASELINE,
       justify = Grid.JUSTIFY.START,
       breakpoint, marginBottom, marginTop,
       }) =>
    <div className={classNames('grid',
        className && className,
        align && 'align--' + align,
        justify && 'justify--' + justify,
        direction && 'direction--' + direction,
        gapX && 'grid-x-' + gapX, gapY && 'grid-y-' + gapY,
        breakpoint && 'to-col-on-' + breakpoint,
        marginBottom && marginBottom, marginTop && marginTop)}
    >
        {children}
    </div>

export default Grid;

Grid.ALIGN = {
    BASELINE: 'baseline',
    START: 'start',
    BOTTOM: 'bottom',
    CENTER: 'center',
    STRETCH: 'stretch',
}

Grid.JUSTIFY = {
    START: 'start',
    CENTER: 'center',
    END: 'end',
    SPACE_BETWEEN: 'space-between'
}

Grid.DIRECTION = {
    ROW: 'row',
    COLUMN: 'column',
}

Grid.GAP = {
    GAP_4:  'gap-4',
    GAP_8:  'gap-8',
    GAP_16: 'gap-16',
    GAP_24: 'gap-24',
    GAP_48: 'gap-48',
}

Grid.BREAKPOINT = {
    TAB_XL: 'tab-xl',  // 1200
    TAB_L: 'tab-l',   // 1023
    TAB: 'tab',       // 800
    MOB: 'mob',       // 768
    MOB_M: 'mob_m',   // 560
    MOB_XS: 'mob_xs', // 370
}

Grid.MARGIN_BOTTOM = {
    MB_XXXL: 'mb-xxxl',
    MB_XXL: 'mb-xxl',
    MB_XL: 'mb-xl',
    MB_L: 'mb-l',
    MB_M: 'mb-m',
}

Grid.MARGIN_TOP = {
    MT_N: 'mt-n',
}