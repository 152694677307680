import React from "react";
import _ from "lodash";
import {_t} from "../../../js/translation";

//TODO properly handle multiple errors
export default ({errors}) => {
    if (!_.isArray(errors)) errors = [errors];

    return errors.map((error, idx) => 
        <div key={idx} className="text-danger">{_t(error)}</div>
    )
}