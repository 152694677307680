import React from "react";
import {Required} from "../index";
import {classNames} from "../../tools/react.dom.helpers";
import Hint from "../../component/hint";
import Errors from "../../component/fields/errors";
import {_t} from "../../../../js/translation";

export const InputGroup = ({className, children, label, required, hintText, hintTitle, inputId, inputGroupRef,
                           direction= InputGroup.DIRECTION.ROW,
                           gapY = InputGroup.GAP.GAP_8,
                           gapX = InputGroup.GAP.GAP_8,
                           align = InputGroup.ALIGN.BASELINE,
                           justify = InputGroup.JUSTIFY.START,
                           breakpoint,
                           errors}) =>
    <div className={classNames('field-group field-group--input-group', className)} ref={inputGroupRef}>
        { label && <label htmlFor={inputId} className="label field-container_title">
            {_t(label)} { required && <Required /> }
            {hintText ? <Hint title={hintTitle} text={hintText} /> : null}
        </label> }

        <div className={classNames('field-container', align && 'align--' + align,  justify && 'justify--' + justify, direction && 'direction--' + direction, gapX && 'grid-x-' + gapX, gapY && 'grid-y-' + gapY, breakpoint && 'to-col-on-' + breakpoint, className)}>
            {children}
        </div>

        {errors && <Errors errors={errors} customClass="form-group__input-error"/>}
    </div>

InputGroup.ALIGN = {
    BASELINE: 'baseline',
    START: 'start',
    BOTTOM: 'bottom',
    CENTER: 'center',
}

InputGroup.JUSTIFY = {
    START: 'start',
    CENTER: 'center',
    END: 'end'
}

InputGroup.DIRECTION = {
    ROW: 'row',
    COLUMN: 'column',
}

InputGroup.GAP = {
    GAP_4:  'gap-4',
    GAP_8:  'gap-8',
    GAP_16: 'gap-16',
    GAP_24: 'gap-24',
}

// !!!!!!!!!!!!!!  Need or Not, need to decide ))
InputGroup.BREAKPOINT = {
    MOB: 'mob',
    MOB_XL: 'mob_xl', // it means break row to columns if screen width less than 480px
    MOB_XS: 'mob_xs', // it means break row to columns if screen width less than 370px - Iphone pro, Galaxy top devices
}