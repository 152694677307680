import React from "react";
import {classNames} from "../../tools/react.dom.helpers";

const ModalBody = ({className, children, noScroll}) => (
    <div className={classNames('modal-body', className && className)}>
        <div className={classNames('modal-body--scroll-box', noScroll && 'modal-body--scroll-box-visible' )}>
            {children}
        </div>
    </div>
);

export default ModalBody;