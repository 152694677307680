import React, { useEffect, useRef } from "react";
import Modal from "./modal/Modal";
import ModalBody from "./modal/ModalBody";

const TutorialPopup = ({ modalId, videoSrc }) => {
    const iframeRef = useRef(null);

    useEffect(() => {
        const modalElement = document.getElementById(modalId);

        const handleModalClose = () => {
            //console.log('Modal is being closed');
            if (iframeRef.current) {
                const iframe = iframeRef.current;
                const iframeSrc = iframe.src;
                iframe.src = '';
                iframe.src = iframeSrc;
            }
        };

        if (modalElement) {
            //console.log('Adding event listener for hidden.bs.modal');
            $(modalElement).on('hidden.bs.modal', handleModalClose);
        }

        return () => {
            if (modalElement) {
                //console.log('Removing event listener for hidden.bs.modal');
                $(modalElement).off('hidden.bs.modal', handleModalClose);
            }
        };
    }, [modalId]);

    return (
        <>
            <Modal id={modalId} className="tutorial-popup" type={Modal.TYPE.INFO} size={Modal.SIZE.XXL}>
                <ModalBody noScroll>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                            ref={iframeRef}
                            id="video-modal-clients-frame"
                            className="embed-responsive-item video"
                            src={videoSrc}
                            width="640"
                            height="312"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default TutorialPopup;
