import React from "react";
import {classNames} from "../tools/react.dom.helpers";
import {_t} from "../../../js/translation";


const Heading = ({className, children, title, type = Heading.TYPE.H1, alignment, weight, ellipsis}) => {

    const headingTag = type => {
        switch (type) {
            case Heading.TYPE.H1:
                return 'h1';
            case Heading.TYPE.H2:
                return 'h2';
            case Heading.TYPE.H3:
                return 'h3';
            case Heading.TYPE.H4:
                return 'h4';
            case Heading.TYPE.HEADING:
                return 'h4';
            case Heading.TYPE.HEADING_SMALL:
                return 'h5';
        }
    };

    const H = headingTag(type);

    return <H className={classNames(className, type && type, weight && weight, alignment && alignment, ellipsis && 'ellipsis')}>
        { children || <>{_t(title)}</> }
    </H>
}

Heading.TYPE = {
    H1: 'title title--h1',
    H2: 'title title--h2',
    H3: 'title title--h3',
    H4: 'title title--h4',
    HEADING: 'heading',
    HEADING_SMALL: 'heading heading--small'
}
Heading.ALIGNMENT = {
    CENTER: 'text-center',
    LEFT: 'text-left',
    RIGHT: 'text-right'
}

Heading.WEIGHT = {
    MEDIUM: 'medium',
    BOLD: 'bold',
}


export default Heading;