import React from "react";
import {classNames} from "../tools/react.dom.helpers";

const Divider = ({className, color = Divider.COLOR.SECONDARY, orientation = Divider.ORIENTATION.HORIZONTAL, behavior, margin = Divider.MARGIN.MEDIUM, fixedMargin, showOnDesktop}) => (
    <div className={classNames(className, 'divider', color && 'divider-color-' + color, orientation && 'divider-orientation-' + orientation, showOnDesktop && 'divider-desktop-show', behavior && 'divider-behavior-' + behavior, margin && 'divider-margin-' + margin, fixedMargin && 'divider-fixed-margin')}></div>
)

Divider.COLOR = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SUCCESS: 'success',
}

Divider.ORIENTATION = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical'
}

Divider.BEHAVIOR = {
    DEFAULT: 'default',
    STATIC: 'fit-by-container'
}

Divider.MARGIN = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    NONE: 'none'
}

export default Divider;