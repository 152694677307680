import React, { useState, useEffect} from "react";

export default ({phone, tablet, desktop, children = null}) => {
    const width = useCurrentWidth();
    return (phone && width < 768) || (tablet && width >= 768 && width < 1024) || (desktop && width >= 1024) ? children : null;
}

function getScreen(width) {
    return width < 768 ? 'phone' : width < 1024 ? 'tablet' : 'desktop';
}

const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

export function useScreen() {
    let width = useCurrentWidth();

    return getScreen(width);
}

export function useCurrentWidth() {
    // save current window width in the state object
    let [width, setWidth] = useState(getWidth());

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
        // timeoutId for debounce mechanism
        let timeoutId = null;
        const resizeListener = () => {
            // prevent execution of previous setTimeout
            clearTimeout(timeoutId);
            // change width from the state object after 150 milliseconds
            timeoutId = setTimeout(() => setWidth(getWidth()), 50);
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    return width;
}
