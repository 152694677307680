import React, { Fragment } from "react";
import { classNames } from "../../tools/react.dom.helpers";
import Icon from "../icon";
import { Visible } from "../../component/visible";
import {_t} from "../../../../js/translation";

export default ({caption, visible=true, icon, selected, onClick, children, hasError = false}) => <Visible visible={visible}>
    <li onClick={onClick} className={classNames('nav-item', selected && "nav-item__active", hasError && "nav-item__error")}>
        { children || <Fragment>
            <a className={classNames('nav-link', selected && 'active')} >
                {icon ? <Icon icon={icon} /> : null}
                {_t(caption)}
            </a>
        </Fragment> }
    </li>
</Visible>
