import React from "react";
import {classNames} from "../tools/react.dom.helpers";

const InfoBlock = ({children, fullWidth, background = InfoBlock.BACKGROUND.SECOND_BRAND_COLOR_SB, padding = InfoBlock.PADDING.DEFAULT, border, className}) => (
    <section className={classNames('info-block', background && 'info-block--bg-' + background, border && 'info-block--has-border',  padding && 'info-block--' + padding, fullWidth && 'info-block--full-width-' + fullWidth, className && className)}>
        {children}
    </section>
);

export default InfoBlock;


InfoBlock.BACKGROUND = {
    LIGHTER_SB: 'lighter-sb',
    SECOND_BRAND_COLOR_SB: 'second-brand-color-sb',
    SECOND_BRAND_COLOR_SM: 'second-brand-color-sm',
    WHITE: 'white',
}

InfoBlock.PADDING = {
    DEFAULT: 'padding-lx',
    MIDDLE: 'padding-l',
    NONE: 'padding-none',
}

InfoBlock.FULL_WIDTH_ON = {
    TABLET: 'tablet',
    MOBILE_XL: 'mobile-xl',
    MOBILE: 'mobile'
}