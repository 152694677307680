import React from "react";
import {childrenWithProps, classNames} from "../../tools/react.dom.helpers";
import Pane from "./pane";

//TODO change 'id' to something not confused with dom element's id
//NB be carefull to have some tab preselected at start
export default class MultiPane extends React.Component {

    childType = Pane;

    constructor(props) {
        super(props);
        this.state = {selectedPane: null};
    }

    render() {
        let selectedPane = this.props.controlled ? this.props.value : this.state.selectedPane;
        return <div className={classNames('tab-container', this.props.className && this.props.className)}>
            {childrenWithProps(this.props.children, (child) => {
                let result = {};

                if (child.type === this.childType) {
                    // render pane component
                    result.visible = selectedPane ? selectedPane === child.props.id : child.props.visible;
                } else {
                    // render switch/tab component
                    result.value = selectedPane || child.props.value;
                    result.onChange = (id) => {
                        this.selectPane(id);
                        this.props.onChange?.(id);
                    }
                }

                return result;
            })}
        </div>
    }

    selectPane(id) {
        this.setState({selectedPane: id});
    }
}


