import React from "react";
import {Visible} from "../../component/visible";
import {classNames} from "../../tools/react.dom.helpers";

export default ({ children, visible, className }) => <Visible visible={visible}>
    <div className={classNames('tab-content', className && className)}>
        <div className={classNames('tab-pane fade', visible && 'active show')}>
            {children}
        </div>
    </div>
</Visible>;
