import React from "react";
import {classNames} from "../../tools/react.dom.helpers";
import {_t} from "../../../../js/translation";


const TabsHeader = ({className, children, type = TabsHeader.TEMPLATE.PRIMARY}) =>
    <ul className={classNames('nav nav-tabs', className, type && ('nav-tabs__' + type),)} role="tablist">
        {children}
    </ul>


TabsHeader.TEMPLATE = {
   PRIMARY: 'primary',
   SECONDARY: 'secondary',
}



export const TabsHeaderItem = ({caption, className, id, onClick, isActive }) =>
    <li className="nav-item">
        <a id={id} className={classNames('nav-link', className, isActive && 'active')} onClick={onClick} data-toggle="tab" role="tab">
            {_t(caption)}
        </a>
    </li>



export default TabsHeader;