import React, {useEffect} from "react";
import {BtnBar, DropdownButton} from "./buttons/button";
import ICON from "./const/icon";
import {childrenWithProps, classNames} from "../tools/react.dom.helpers";
import {useResizeDetector} from "react-resize-detector";
import {Icon} from "./index";

/**
 * @see htdocs/v2/js/management/app/view/controller/plugins/PluginsFormView.js
 */
const CollapsibleMenu =  ({className, children, caption = 'More', header, type = CollapsibleMenu.TYPE.DOTS, priority=CollapsibleMenu.PRIORITY.HIDE_LAST, position = DropdownButton.POSITION.RIGHT, btnTemplate}) => {

    const { width, ref } = useResizeDetector({
        handleHeight: false,
        refreshMode: 'throttle',
        refreshRate: 500,
    });

    const hide = (elements, isDropdown) => {
        if (!elements?.length) return;
        for (var element of elements) {
            isDropdown ? element.style.display = 'none' : element.hidden = true;

        }
    }

    const show = (elements, isDropdown) => {
        if (!elements?.length) return;
        for (var element of elements) {
            isDropdown ? element.style.display = 'block' : element.hidden = false;
        }
    }

    const visible = elements => [...elements].filter(element => !element.hidden);
    const totalWidth = elements => [...elements].map(element => element.offsetWidth + 8).reduce((a, b) => a + b, 0);
    const last = elements => elements[elements.length - 1];
    const first = elements => elements[0];

    useEffect(() => {
        const buttonContainer = ref.current;

        if(buttonContainer === null) return;

        const buttonSubmenu = buttonContainer.getElementsByClassName('button-submenu');
        const buttonInSubmenu = buttonContainer.getElementsByClassName('button-in-submenu');
        const buttonOutsideSubmenu = buttonContainer.getElementsByClassName('button-outside-submenu');

        hide(buttonSubmenu, true);
        hide(buttonInSubmenu, false);
        show(buttonOutsideSubmenu, false);

        if (!buttonOutsideSubmenu?.length && !buttonInSubmenu?.length) return;
        do {
            let childrenWidth = totalWidth(visible(buttonSubmenu)) + totalWidth(visible(buttonOutsideSubmenu));
            if (width > childrenWidth + 10) break;

            let lastVisibleButton = priority == CollapsibleMenu.PRIORITY.HIDE_LAST ?
                last(visible(buttonOutsideSubmenu)) :
                first(visible(buttonOutsideSubmenu));

            show(buttonSubmenu, true);
            show([...buttonInSubmenu].filter(element => element.id == lastVisibleButton.id), false);
            hide([lastVisibleButton], false);
        } while(visible(buttonOutsideSubmenu).length);
    });

    const innerButtons = (childrenWithProps(children, (child, id) => ({
        className: 'button-in-submenu',
        id: child.props.id || `button_${id}`
    })))

    const dropdown = (
        type == CollapsibleMenu.TYPE.BARS  ?
            <DropdownButton containerClass="button-submenu" template={DropdownButton.TEMPLATE.SECONDARY} icon={Icon.ICON.MENU_BARS} header={header} position={position} mobBar>
                {innerButtons}
            </DropdownButton> :
        type == CollapsibleMenu.TYPE.DOTS  ?  //TODO "ellipsis-vertical" icon!
            <DropdownButton containerClass="button-submenu" template={DropdownButton.TEMPLATE.SECONDARY} icon={Icon.ICON.MENU_DOTS} header={header} position={position} mobBar>
                {innerButtons}
            </DropdownButton> :
        type == CollapsibleMenu.TYPE.MORE_ACTIONS  ?
            <DropdownButton containerClass="button-submenu" template={btnTemplate} caption={caption} caret header={header} position={position} mobBar>
                {innerButtons}
            </DropdownButton> :
        null
    );

    return <div className={classNames('btn-bar__adaptive-wrapper', className)} ref={ref}>
        <BtnBar className={'btn-bar__adaptive'}>
            { priority == CollapsibleMenu.PRIORITY.HIDE_FIRST ? dropdown : null }
            {childrenWithProps(children, (child, id) => ({
                className: 'button-outside-submenu',
                id: child.props.id || `button_${id}`
            }))}
            { priority == CollapsibleMenu.PRIORITY.HIDE_LAST ? dropdown : null }
        </BtnBar>
    </div>
}

CollapsibleMenu.PRIORITY = {
    HIDE_FIRST: 'hide-first',
    HIDE_LAST: 'hide-last'
};

CollapsibleMenu.TYPE = {
    BARS: 'bars',
    DOTS: 'dots',
    MORE_ACTIONS: 'more-actions'
};

CollapsibleMenu.JUSTIFY = BtnBar.JUSTIFY;

export default CollapsibleMenu;