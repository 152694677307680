import React from "react";
import {Heading} from "./index";
import {classNames} from "../tools/react.dom.helpers";
import Hint from "../component/hint";
import {_t} from "../../../js/translation";


const Fieldset = ({className, headingClassName = '',  type = Fieldset.TYPE.FORM, title, translatedTitle, titleType = Heading.TYPE.H3, titleWeight, hideTitleOnMob, required, children, hintText}) =>
    <div className={classNames(className, 'fieldset', type && 'fieldset--type-' + type, hideTitleOnMob && 'fieldset--hide-title-on-mob')}>
        {/*<div className={"fieldset--readonly-surface"}></div>*/}
        {(title || translatedTitle) && <div className="fieldset__header">
            <Heading
                className={classNames('fieldset__header-title', headingClassName)}
                translatedTitle={translatedTitle}
                type={titleType}
                weight={titleWeight}
                required={required}
            >
                {_t(title)}
                {hintText ? <Hint text={hintText}/> : ''}
            </Heading>
        </div>}
        {children}
    </div>

export default Fieldset;

Fieldset.TYPE = {
    FILTER: 'filter',
    FORM: 'form',
}