import React, {Fragment, useEffect, useState} from "react";
import {childrenWithProps, classNames} from "../../tools/react.dom.helpers";

const Tabs = ({children, controlled, value, onChange, className, type = Tabs.TEMPLATE.PRIMARY}) => {
    let selectedValue = value;

    if (!selectedValue) {
        children.forEach((child) => {
            if (selectedValue === undefined || child.props.selected) {
                selectedValue = child.props.value;
            }
        });
    }

    const [selectedTab, setSelectedTab] = useState(selectedValue);

    useEffect(() => {
        if (value) {
            onChange?.(selectedValue);
        }
    }, [])

    const selectTab = tab => {
        setSelectedTab(tab);
        onChange?.(tab);
    }

    return <div className={classNames('nav-tabs__container',  className, type && ('nav-tabs__' + type))}>
        <div className={'nav-tabs__scrollblock'}>
            <ul className={'nav nav-tabs'} >
                {childrenWithProps(children, (child) => ({
                    selected: controlled ?
                        (value ? value == child.props.value : child.props.selected) :
                        selectedTab == child.props.value,
                    onClick: () => selectTab(child.props.value)
                }))}
            </ul>
        </div>
    </div>
}

Tabs.TEMPLATE = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
}

export default Tabs;