import React, {Fragment} from "react";
import {classNames} from "../tools/react.dom.helpers";
import {_t} from "../../../js/translation";
import {Icon} from "./index";

const Alert = ({color=Alert.COLOR.HINT, className, dismissible, caption, children, mb, onClickClose}) =>
    <div className={classNames('alert', color && 'alert-' + color, dismissible && 'alert-dismissible fade show', className, mb && mb)} role="alert">
        {/*<div className={classNames('alert__content')}>*/}
            { dismissible && <button type="button" className="close" onClick={onClickClose} aria-label="Close"><Icon icon={'times'}/></button> }
            {/*{*/}
            {/*    color == Alert.COLOR.SUCCESS ? <Icon icon="check" /> :*/}
            {/*    color == Alert.COLOR.DANGER ? <Icon icon="exclamation-triangle" /> :*/}
            {/*    color == Alert.COLOR.WARNING ? <Icon icon="exclamation-circle" /> :*/}
            {/*    color == Alert.COLOR.DANGER_FILLED ? <Icon icon="exclamation-circle" /> :*/}
            {/*    color == Alert.COLOR.SECONDARY ? <Icon icon="exclamation-circle" /> :*/}
            {/*    color == Alert.COLOR.SECONDARY_FILLED ? <Icon icon="info-circle" /> :*/}
            {/*    color == Alert.COLOR.HINT ? null :*/}
            {/*    null*/}
            {/*}*/}
            { children || <Fragment>
                {caption && <React.Fragment>{_t(caption)}</React.Fragment>}
            </Fragment> }
        {/*</div>*/}
    </div>

Alert.COLOR = {
    DANGER: 'danger',
    WARNING: 'warning',
    SUCCESS: 'success',
    HINT: 'info',
    ISO: 'iso',
}

Alert.MARGIN_B = {
    NONE: 'mb-0',
    XL: 'mb-20'
}
export default Alert;
