const phpToMomentFormatMap = {
    'm-d-Y': 'MM-DD-YYYY',
    'Y-m-d': 'YYYY-MM-DD',
    'd-m-Y': 'DD-MM-YYYY',
    'm.d.Y': 'MM.DD.YYYY',
    'Y.m.d': 'YYYY.MM.DD',
    'd.m.Y': 'DD.MM.YYYY',
    'y.m.d': 'YY.MM.DD',
    'd.m.y': 'DD.MM.YY',
    'm.d.y': 'MM.DD.YY',
    'm-d': 'MM-DD',
    'd-m': 'DD-MM',
    'm/d': 'MM/DD',
    'd/m': 'DD/MM',
    'm.d': 'MM.DD',
    'd.m': 'DD.MM',
    'l, M d Y': 'dddd, MMM D YYYY',
    'l, d-m-Y': 'dddd, DD-MM-YYYY'
};

const phpToDateFormatMap = {
    'm-d-Y': 'MM-dd-yyyy',
    'Y-m-d': 'yyyy-MM-dd',
    'd-m-Y': 'dd-MM-yyyy',
    'm.d.Y': 'MM.dd.yyyy',
    'Y.m.d': 'yyyy.MM.dd',
    'd.m.Y': 'dd.MM.yyyy',
    'y.m.d': 'yy.MM.dd',
    'd.m.y': 'dd.MM.yy',
    'm.d.y': 'MM.dd.yy',
    'm-d': 'MM-dd',
    'd-m': 'dd-MM',
    'm/d': 'MM/dd',
    'd/m': 'dd/MM',
    'm.d': 'MM.dd',
    'd.m': 'dd.MM',
    'l, M d Y': 'EEEE, MMM d yyyy',
    'l, d-m-Y': 'EEEE, dd-MM-yyyy'
};

const convertPHPDateFormat = (phpDateFormat, type = 'moment') => {
    return type === 'moment'
        ? phpToMomentFormatMap[phpDateFormat]
        : phpToDateFormatMap[phpDateFormat];
};

export default convertPHPDateFormat;