import React from "react";
import _ from "lodash";
import {childrenArray, childrenWithProps, classNames} from "../../tools/react.dom.helpers";
import {_t} from "../../../../js/translation";
import {BtnBar, Heading} from "../index";

const PageHeader = ({ caption, border, rightColumn, hasBackBtn, marginBottom = PageHeader.MARGIN_BOTTOM.MB_XXL, children }) => {
    const hasBtnBar = _.find(childrenArray(children), child => child.type === BtnBar);

    return <header className={classNames('page-header', border && '__has-border', hasBtnBar && '__has-actions', rightColumn && '__has-actions', hasBackBtn && '__has-back-btn', marginBottom && marginBottom)}>
        <div className="page-header--row">
            <Heading className="page-header--item page-header--item-title" type={Heading.TYPE.H1}>
                {_.isString(caption) ? _t(caption) : caption}
                {children}
            </Heading>
            {rightColumn &&
                <div className="page-header--item page-header--item-right">
                    {childrenWithProps(rightColumn, (child, id) => ({
                        key: id
                    }))}
                </div>
            }
        </div>
    </header>
}
export default PageHeader;

PageHeader.MARGIN_BOTTOM = {
    MB_XXL: 'mb-xxl',
    MB_XL: 'mb-xl',
    MB_L: 'mb-l',
    MB_M: 'mb-m',
}