import React from "react";
import {BtnBar, ClosePopupButton} from "../buttons/button";
import {Button, Grid} from "../index";
import IfScreen from "../../../super-admin/panel/modules/components/uikit/if.screen";

const ModalFooter = ({children, cancelModal, dynamicBar}) => {
    return <div className={'modal-footer'} >
        {dynamicBar ? <Grid className={'dynamic-bar'} gapX={Grid.GAP.GAP_8} breakpoint={false}>
            <IfScreen desktop tablet>
                <ClosePopupButton template={Button.TEMPLATE.SECONDARY_OUTLINE} caption={'Close'} onClick={cancelModal}/>
            </IfScreen>

            {children}
        </Grid>
        :
        <BtnBar alignment={BtnBar.ALIGNMENT.CENTER} content={BtnBar.CONTENT.RIGHT}>
            <IfScreen desktop tablet>
                <ClosePopupButton template={Button.TEMPLATE.SECONDARY_OUTLINE} caption={'Close'} onClick={cancelModal}/>
            </IfScreen>

            {children}
        </BtnBar>}
    </div>
};

export default ModalFooter;