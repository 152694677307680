import React from "react";
import { generateId } from "../../tools/react.dom.helpers";
import ValidationError from "../validation.error";
// import InfoHint from "../info_hint";
import Required from "./required";
import classNames from "classnames";
import {_t} from "../../../../js/translation";

/**
 * @todo value, checked - which interface we choose? (<input value="1" checked={value} /> or <input value={value} checked={checked}/> ?)
 * @todo how we deal with controlled/uncontrolled components?
 */
export default ({id = generateId(), name, label, children, value=1, checked = 0, semiChecked = 0, disabled, errors, onChange, required, controlled, help, nested}) => {

    const checkedProp = controlled ? {
        checked: checked || semiChecked
    } : {
        defaultChecked: checked || semiChecked
    };

    const nestedInput = <>
        <div className="field-container">
            <div className="checkbox">
                <input name={name} id={id} type="checkbox" className="checkbox__input" disabled={disabled}
                    value={value} {...checkedProp}
                    onChange={(evt) => onChange?.(evt.target.checked)} />
                <span className={`checkbox__box ${semiChecked && !checked ? "semi-checked" : ""}`} />
            </div>
        </div>

        {
            (label || children) ? <label htmlFor={id} className="label">
                { children || _t(label) } { required && <Required /> }
                {/*{ help && <InfoHint icon={InfoHint.ICON.INFO_SQUARE} text={help} /> }*/}
            </label> : null
        }
        { errors && <ValidationError errors={errors} />}
    </>
 
    return nested ? nestedInput : 
        <div className={classNames('field-group field-group--checkbox', disabled && 'field-group--disabled')}>
            { nestedInput }
        </div>
}
