import React from "react";
import _ from "lodash";
import {Heading, Text} from "./index";
import {childrenWithProps, classNames} from "../tools/react.dom.helpers";
import {_t} from "../../../js/translation";


const NoData = ({contentText, actions, caption, subcaption, imageSrc,
   headingType = Heading.TYPE.H3, marginBottom, children}) => {

    return <section className={classNames('no-data-card', marginBottom && marginBottom)}>
        <picture className="no-data-card__picture">
            <img className="empty-state-image" src={imageSrc} alt={'no-data-image'}/>
        </picture>
        <div className="no-data-card__header">
            <Heading className="section-header__title" type={headingType} weight={Heading.WEIGHT.MEDIUM}>
                {_.isString(caption) ? _t(caption) : caption}
            </Heading>
            {subcaption && <Text color={Text.COLOR.SECONDARY} caption={subcaption}/>}
        </div>
        <div className="no-data-card__content">
            {childrenWithProps(contentText, (child, id) => ({
                key: id
            }))}
            {children}
        </div>
        {actions &&
            <div className="no-data-card__action">
                {childrenWithProps(actions, (child, id) => ({
                    key: id
                }))}
            </div>
        }
    </section>
}
export default NoData;

NoData.MARGIN_BOTTOM = {
    M: 'mb-m',
    L: 'mb-l',
}