import React from "react";
import {childrenArray, classNames} from "../../tools/react.dom.helpers";
import {Icon, Text} from "../index";
import {_t} from "../../../../js/translation";

const ListItem = ({type, caption, captionSize, captionWeight, captionColor, className, color, help, multiple, icon, iconColor=Icon.COLOR.SECONDARY, feetByContent, children}) => {
    return (
        <li className={classNames('list-item', type, className, color && 'text-' + color, icon && 'list-item--with-icon')}>
            {icon && <Icon className="list-item__icon" color={iconColor} type={Icon.TYPE.LIGHT} icon={icon}/>}

            {caption && <Text size={captionSize} weight={captionWeight}
                              className={classNames('list-item__caption', feetByContent && 'list-item__caption-feet-by-content')}
                              color={captionColor}>
                {_t(caption)}
            </Text>}

            {multiple ?
                childrenArray(children).map((child, i) =>
                    <ListItemCol key={`item_${i}`}>
                        {child}
                    </ListItemCol>
                ) :
                <ListItemCol>
                    {children}
                </ListItemCol>
            }
        </li>
    );
}

ListItem.COLOR = {
    DANGER: 'danger',
    SUCCESS: 'success',
    WARNING: 'warning',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    DARKER: 'darker'
};

ListItem.TYPE = {
    HEADING: 'list-item--heading',
    STEP: 'list-item--step',
};

export default ListItem;

const ListItemCol = ({className, children}) =>
    <div className={classNames('list-item__info', className)}>
        {children}
    </div>