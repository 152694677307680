import React from "react";
import { classNames } from "../../tools/react.dom.helpers";

const stopPropagation = (handler) =>
    handler ?
        (event) => {
            event.stopPropagation();
            handler(event);
        }
        : null

const List = ({style = List.STYLE.NONE, type, className, children, onClick}) => (
    <ul className={classNames('list', type && type, style && style, className)} onClick={stopPropagation(onClick)}>
        {children}
    </ul>
);

List.STYLE = {
    NONE: '',
    CHECKED: 'list--checked'
}

List.TYPE = {
    ROWS: 'list--rows',
    INLINE: 'list--inline',
    COLUMNS: 'list--columns',
    GUIDELINE_STEPS: 'list--guideline-steps',
    ACTION_STEPS: 'list--action-steps',
    INLINE_ITEMS: 'list--inline-items',
}

export default List;